import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
     

     <path d="M7617 8642 c-15 -9 -30 -26 -33 -37 -4 -11 -15 -28 -26 -39 -14 -13
-18 -27 -14 -46 4 -21 1 -28 -9 -26 -8 1 -17 5 -21 9 -8 8 -74 -62 -74 -79 0
-7 -6 -19 -13 -26 -11 -11 -49 -81 -98 -180 -5 -10 -9 -31 -9 -48 0 -18 -6
-33 -15 -36 -11 -5 -15 -22 -15 -64 0 -39 -5 -62 -15 -70 -9 -8 -15 -30 -15
-59 0 -26 -7 -56 -16 -69 -20 -29 -26 -264 -6 -283 7 -8 12 -41 12 -84 1 -42
6 -80 15 -95 8 -14 14 -39 15 -56 0 -17 7 -37 15 -44 8 -7 15 -25 15 -41 0
-16 4 -37 9 -47 6 -9 22 -42 36 -72 61 -126 152 -257 234 -333 55 -52 85 -58
119 -27 37 35 27 59 -43 108 -5 4 -26 30 -45 57 -19 28 -47 63 -62 78 -15 16
-28 37 -28 47 0 10 -7 20 -15 24 -8 3 -15 15 -15 26 0 19 -25 68 -41 80 -12 9
-49 123 -49 152 0 15 -4 29 -9 32 -4 3 -14 26 -21 51 -13 47 -32 71 -50 60 -9
-5 -15 15 -11 38 0 4 -2 7 -7 7 -4 0 -7 13 -7 29 0 16 4 27 9 23 5 -3 7 5 4
16 -4 12 -3 24 1 26 4 3 8 24 10 48 1 24 6 47 11 52 6 6 2 15 -8 23 -11 9 -11
12 -2 7 11 -6 10 -2 -3 13 -9 11 -14 23 -10 27 5 4 3 6 -2 4 -11 -3 -15 4 -15
30 0 14 2 15 10 2 7 -11 10 -3 10 29 0 41 1 43 14 26 12 -17 14 -15 21 26 9
54 34 119 46 119 5 0 9 15 9 33 0 18 7 43 16 56 14 19 14 27 4 46 -14 27 -7
45 20 45 11 0 22 10 26 23 4 12 19 40 33 62 34 53 46 74 66 112 9 18 30 41 47
51 48 30 82 99 68 136 -14 38 -40 47 -73 28z m13 -97 c0 -8 -4 -15 -10 -15 -5
0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-303 -457 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-19 -358 c-2 -52 -6 -67 -18 -66 -24 2
-28 18 -17 77 7 34 16 56 24 56 10 0 13 -17 11 -67z m32 -336 c0 -7 -4 -15 -9
-18 -11 -7 -21 17 -21 47 1 22 1 22 15 3 8 -11 15 -25 15 -32z"/>
<path d="M9321 8606 c-16 -29 -9 -52 27 -91 153 -164 269 -418 302 -661 13
-95 9 -332 -6 -356 -5 -7 -15 -38 -22 -68 -7 -30 -16 -71 -21 -90 -7 -30 -54
-135 -81 -180 -5 -8 -26 -44 -47 -80 -21 -36 -48 -75 -60 -87 -13 -12 -23 -26
-23 -32 0 -5 -15 -22 -34 -38 -63 -53 -65 -98 -7 -123 28 -11 34 -10 64 13 18
14 44 40 58 57 13 18 39 49 57 70 17 21 32 41 32 45 0 5 11 22 24 39 32 42 86
151 86 173 0 10 7 28 15 40 8 12 17 35 18 53 2 17 9 36 15 42 17 17 16 98 -1
98 -8 0 -14 -7 -15 -15 -1 -8 -3 -22 -4 -30 -2 -17 -7 -18 -27 -6 -10 6 -11
22 -7 62 30 258 30 378 0 642 -4 33 -2 47 6 47 7 0 14 -9 17 -20 3 -11 10 -20
15 -20 12 0 -1 64 -13 68 -5 2 -9 16 -9 30 0 26 -39 132 -52 142 -3 3 -10 18
-13 33 -4 15 -11 27 -15 27 -5 0 -12 10 -16 22 -7 25 -132 178 -144 178 -4 0
-10 9 -13 21 -3 12 -10 18 -17 14 -6 -4 -9 -13 -6 -20 5 -15 -27 -30 -43 -21
-5 3 -7 15 -4 26 8 29 -21 26 -36 -4z m129 -96 c0 -5 -7 -10 -16 -10 -8 0 -12
5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m64 -71 c-3 -5 -10 -7 -15 -3 -5 3
-7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m141 -297 c-3 -8 -8 -13 -11 -10
-9 9 -14 51 -7 61 9 14 25 -33 18 -51z m21 -849 c8 -30 -12 -55 -26 -33 -5 8
-10 27 -10 42 0 35 27 28 36 -9z m-76 -97 c0 -22 -11 -46 -21 -46 -10 0 -12
43 -2 53 11 12 23 8 23 -7z"/>
<path d="M4707 8539 c-170 -18 -373 -92 -518 -189 -96 -64 -275 -251 -332
-347 -124 -208 -167 -371 -167 -638 0 -203 22 -333 82 -473 124 -293 339 -506
632 -627 178 -74 215 -77 814 -82 l532 -5 0 -229 0 -229 -884 0 c-579 0 -887
-3 -891 -10 -4 -7 -472 -10 -1371 -10 l-1364 0 0 -480 0 -480 2255 0 2255 0 0
-811 0 -811 34 -34 34 -34 226 0 c212 0 227 1 246 20 28 28 32 54 22 140 l-9
75 -2 -76 c-1 -61 -5 -82 -22 -103 l-20 -26 -210 0 c-125 0 -218 4 -230 10
-51 28 -48 -73 -49 1900 l-1 1833 31 32 c19 20 50 38 76 44 28 7 201 11 491
11 448 0 448 0 423 20 -61 49 -243 131 -357 162 l-118 33 -780 5 -780 5 -36
23 c-54 33 -72 79 -77 195 -4 79 -1 106 13 138 20 43 62 80 114 97 25 9 322
12 1132 12 l1099 0 1 48 c0 26 1 238 2 472 l2 425 -1110 1 c-610 1 -1145 -2
-1188 -7z"/>
<path d="M9116 8374 c-7 -18 8 -46 49 -90 14 -16 44 -55 65 -89 21 -33 44 -65
49 -72 6 -7 11 -18 11 -25 1 -7 9 -31 20 -53 11 -22 19 -46 20 -52 0 -7 5 -13
10 -13 13 0 13 55 1 78 -5 9 -26 52 -47 94 -21 43 -49 90 -64 105 -15 16 -38
46 -52 68 -35 56 -54 71 -62 49z"/>
<path d="M8330 8295 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M8952 8089 c12 -16 24 -25 26 -19 4 12 -26 50 -40 50 -5 0 2 -14 14
-31z"/>
<path d="M3335 8043 c-16 -2 -83 -10 -148 -19 -242 -30 -523 -123 -767 -254
-658 -355 -1106 -997 -1214 -1741 -8 -57 -16 -142 -18 -189 l-3 -85 928 -3
927 -2 0 585 0 585 225 0 225 0 0 565 0 565 -62 -1 c-35 -1 -76 -4 -93 -6z"/>
<path d="M9001 8034 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9032 7975 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M9730 7991 c0 -5 5 -13 10 -16 6 -4 9 -9 6 -13 -2 -4 0 -16 5 -27 7
-17 8 -16 8 8 1 16 -6 35 -14 43 -8 9 -15 11 -15 5z"/>
<path d="M9360 7918 c0 -34 4 -58 10 -58 7 0 10 19 9 45 -4 68 -19 78 -19 13z"/>
<path d="M9062 7905 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M7563 7845 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M7556 7674 c-8 -22 -6 -75 4 -99 6 -14 9 1 9 48 1 68 -2 80 -13 51z"/>
<path d="M7343 7610 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
<path d="M9748 7598 c-3 -7 -6 -35 -7 -63 -2 -27 -5 -53 -7 -57 -3 -5 2 -8 10
-8 13 0 16 13 16 70 0 68 -3 83 -12 58z"/>
<path d="M8116 7553 c-9 -22 10 -41 25 -26 13 13 5 43 -10 43 -5 0 -12 -8 -15
-17z"/>
<path d="M7585 7497 c-4 -10 -3 -32 2 -50 l10 -32 8 28 c4 16 3 38 -3 50 -9
20 -10 21 -17 4z"/>
<path d="M7917 7493 c-3 -5 -1 -17 4 -28 7 -17 8 -17 8 8 1 27 -4 34 -12 20z"/>
<path d="M8397 7493 c-10 -10 -8 -53 2 -53 23 0 41 14 41 30 0 10 -7 21 -16
24 -19 7 -19 7 -27 -1z"/>
<path d="M8850 7480 c0 -13 5 -20 13 -17 6 2 12 10 12 17 0 7 -6 15 -12 18 -8
2 -13 -5 -13 -18z"/>
<path d="M8845 7424 c-6 -8 -30 -18 -53 -21 -24 -3 -46 -12 -49 -19 -3 -8 -18
-14 -34 -14 -26 0 -29 -3 -23 -24 8 -33 -31 -58 -83 -54 -37 3 -38 4 -43 48
-4 40 -8 45 -32 48 -27 3 -28 1 -28 -41 0 -50 -8 -57 -70 -57 -21 0 -41 -4
-44 -9 -11 -17 6 -41 29 -41 28 0 40 -16 37 -47 -3 -17 3 -22 25 -25 l28 -3
-3 -80 c-4 -92 -20 -115 -78 -115 -27 0 -34 -4 -34 -19 0 -10 8 -22 18 -26 26
-11 332 -13 332 -2 0 5 -9 11 -21 14 -20 5 -19 6 3 17 29 14 37 76 14 96 -9 7
-16 19 -16 27 0 20 -42 74 -65 83 -11 4 -27 20 -35 35 -12 23 -12 27 0 35 8 6
18 10 21 10 11 0 44 -35 44 -47 0 -7 5 -13 10 -13 6 0 10 9 10 20 0 11 10 27
23 35 27 20 28 36 2 60 -22 20 -26 50 -9 61 6 3 16 1 21 -4 12 -12 91 -5 121
10 17 9 22 34 11 62 -8 20 -16 20 -29 0z m-216 -345 c27 -33 41 -62 41 -80 0
-28 -2 -29 -45 -29 -53 0 -65 16 -65 84 0 48 7 76 20 76 5 0 27 -23 49 -51z"/>
<path d="M7946 7422 c-2 -4 -1 -14 5 -22 7 -12 9 -10 9 8 0 23 -5 28 -14 14z"/>
<path d="M7610 7387 c0 -24 5 -40 15 -43 8 -4 15 -19 15 -34 0 -16 4 -32 10
-35 5 -3 20 -28 32 -55 13 -28 26 -50 30 -50 4 0 19 -20 34 -45 14 -25 30 -45
35 -45 5 0 9 -6 10 -12 0 -7 16 -26 35 -42 30 -25 39 -27 67 -19 46 13 44 46
-4 53 -40 7 -86 37 -94 63 -4 9 -10 17 -14 17 -8 0 -34 35 -83 109 -29 44 -58
104 -58 121 0 9 -7 25 -15 36 -13 18 -14 17 -15 -19z"/>
<path d="M8275 7148 c3 -144 9 -266 14 -271 5 -5 17 -7 25 -4 14 6 16 39 16
256 0 137 -3 256 -6 265 -3 9 -17 16 -30 16 l-24 0 5 -262z"/>
<path d="M8007 7321 c3 -12 10 -21 15 -21 13 0 6 32 -9 37 -7 3 -9 -3 -6 -16z"/>
<path d="M8040 7284 c0 -13 40 -46 52 -42 7 3 18 -2 25 -11 11 -14 12 -13 13
7 0 14 -6 22 -17 22 -10 0 -30 7 -45 14 -16 8 -28 13 -28 10z"/>
<path d="M8391 7107 c-14 -14 -14 -44 0 -53 10 -6 48 31 49 47 0 12 -38 17
-49 6z"/>
<path d="M9116 7089 c-20 -29 -5 -59 29 -59 25 0 33 14 15 25 -5 3 -10 15 -10
25 0 29 -17 33 -34 9z"/>
<path d="M8947 6763 c-4 -21 -7 -837 -7 -1813 0 -1743 0 -1776 -20 -1807 -39
-65 -24 -63 -612 -63 -589 0 -564 -2 -648 66 -20 16 -254 256 -520 534 -266
278 -533 557 -594 620 -62 63 -156 161 -211 218 -122 127 -141 131 -137 32 1
-36 4 -90 7 -120 3 -30 14 -172 25 -315 11 -143 22 -289 25 -325 21 -259 35
-453 35 -474 0 -14 4 -26 9 -26 5 0 7 8 5 18 -2 9 -8 96 -14 192 -6 96 -13
193 -15 215 -6 48 -53 652 -62 783 -4 60 -2 92 4 92 6 0 45 -37 87 -81 67 -72
243 -256 651 -679 66 -69 248 -259 404 -422 255 -268 289 -301 349 -330 l67
-33 535 0 c479 0 538 2 565 17 44 24 72 58 84 103 13 49 15 3602 2 3623 -5 9
-10 1 -14 -25z"/>
<path d="M6643 6714 c-7 -3 -13 -9 -13 -13 0 -9 487 -495 500 -499 6 -2 11 53
12 133 l3 137 508 -503 c365 -361 508 -496 505 -478 -3 27 -1159 1178 -1212
1207 -24 12 -62 17 -161 19 -71 1 -136 0 -142 -3z"/>
<path d="M1451 4613 c12 -21 35 -63 52 -93 51 -92 156 -240 237 -335 322 -375
729 -631 1210 -759 346 -93 792 -97 1132 -11 53 14 101 25 107 25 7 0 11 -52
11 -155 l0 -155 635 0 635 0 0 750 0 750 -500 0 -500 0 0 -173 0 -174 -212 -6
c-116 -4 -438 -7 -715 -7 l-503 0 0 190 0 190 -805 0 -805 0 21 -37z"/>
<path d="M2628 2926 c-83 -30 -138 -91 -158 -176 -15 -61 -8 -107 26 -181 79
-169 349 -169 428 0 36 78 42 125 22 156 -16 24 -21 25 -114 25 -108 0 -122
-6 -122 -55 0 -42 27 -65 76 -65 l39 -1 -23 -19 c-13 -11 -41 -25 -62 -31 -31
-9 -42 -9 -66 5 -16 8 -36 18 -45 22 -23 8 -39 48 -39 95 0 31 7 47 34 77 l34
37 109 5 c106 5 108 6 121 33 11 22 11 32 0 55 -13 27 -14 27 -118 29 -68 2
-118 -2 -142 -11z m211 -12 c2 -2 -38 -3 -90 -3 -60 1 -83 4 -64 8 28 7 145 3
154 -5z m18 -181 c-20 -2 -52 -2 -70 0 -17 2 0 4 38 4 39 0 53 -2 32 -4z"/>
<path d="M3086 2918 c-13 -19 -16 -57 -16 -229 0 -183 2 -209 18 -229 17 -21
22 -21 205 -18 212 3 209 2 205 78 l-3 45 -145 5 c-80 3 -144 8 -142 13 1 4 2
11 2 16 0 5 8 12 18 14 13 4 11 5 -5 6 -22 1 -23 4 -23 101 l0 99 142 3 143 3
13 28 c11 22 11 32 0 55 l-13 27 -192 3 c-189 2 -192 2 -207 -20z m371 -2 c3
-3 -72 -6 -168 -5 -96 1 -163 4 -151 7 30 9 310 7 319 -2z m-59 -373 c-32 -2
-86 -2 -120 0 -35 2 -9 3 57 3 66 0 94 -1 63 -3z"/>
<path d="M3626 2924 c-18 -18 -21 -56 -6 -85 9 -16 22 -19 75 -19 l65 0 0
-174 c0 -195 4 -206 65 -206 59 0 65 20 65 212 l0 168 59 0 c70 0 91 15 91 65
0 24 -6 38 -19 45 -12 6 -95 10 -200 10 -150 0 -182 -3 -195 -16z m359 -4 c4
-7 -52 -9 -162 -8 l-168 2 145 6 c80 3 153 7 162 8 9 1 20 -2 23 -8z"/>
<path d="M4673 2921 c-52 -24 -77 -68 -76 -137 1 -102 53 -144 188 -153 84 -6
105 -13 105 -36 0 -21 -24 -25 -148 -25 -64 0 -122 -4 -128 -8 -18 -12 -25
-59 -13 -86 15 -32 32 -36 179 -36 134 0 184 13 224 59 29 32 29 149 0 188
-33 45 -79 63 -161 63 -80 0 -123 12 -123 34 0 28 37 36 158 36 102 0 121 3
135 18 21 23 22 51 1 80 -14 21 -22 22 -157 22 -118 0 -149 -4 -184 -19z m297
-5 c8 -2 -48 -4 -125 -4 -77 0 -131 3 -121 7 18 7 211 4 246 -3z m-122 -183
c-21 -2 -55 -2 -75 0 -21 2 -4 4 37 4 41 0 58 -2 38 -4z m-35 -190 c-29 -2
-78 -2 -110 0 -32 2 -8 3 52 3 61 0 87 -1 58 -3z"/>
<path d="M5152 2928 c-8 -8 -12 -59 -11 -167 1 -192 14 -241 77 -285 38 -27
48 -29 129 -30 178 0 223 65 223 325 0 112 -3 141 -16 153 -18 19 -77 21 -92
4 -5 -7 -12 -77 -14 -155 l-3 -143 -33 -30 c-42 -38 -73 -38 -114 -1 l-33 29
-5 147 c-4 115 -8 148 -20 155 -21 14 -74 12 -88 -2z m53 -8 c3 -5 1 -10 -4
-10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m312 -6 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M5699 2923 c-20 -23 -25 -417 -6 -453 12 -25 14 -25 163 -28 142 -2
152 -1 184 20 47 33 69 66 76 120 5 33 2 55 -10 79 -15 30 -15 35 0 67 31 66
5 141 -66 190 -30 20 -44 22 -180 22 -125 0 -150 -3 -161 -17z m281 -3 c10 -7
-28 -10 -118 -10 -86 0 -131 4 -127 10 8 13 225 13 245 0z m7 -116 c10 -27
-11 -49 -53 -52 -21 -2 -44 -8 -51 -14 -29 -24 -3 -106 35 -109 9 -1 1 -5 -18
-9 -31 -7 -28 -8 23 -9 50 -1 58 -4 55 -18 -3 -15 -16 -18 -82 -21 l-79 -3 5
26 c3 14 1 25 -3 25 -5 0 -9 45 -9 100 l0 100 85 0 c70 0 87 -3 92 -16z m-14
-71 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-50 -190 c-18 -2
-48 -2 -65 0 -18 2 -4 4 32 4 36 0 50 -2 33 -4z"/>
<path d="M6698 2928 c-14 -11 -17 -45 -20 -236 -3 -212 -2 -224 16 -238 25
-18 80 -18 94 -1 7 7 13 65 14 131 l3 119 63 -82 c34 -45 62 -85 62 -89 0 -4
18 -26 40 -49 32 -35 46 -43 74 -43 64 0 66 6 66 250 l0 220 -24 15 c-14 9
-37 15 -53 13 -43 -5 -54 -38 -51 -151 2 -53 1 -97 -2 -97 -3 0 -43 50 -90
111 -46 61 -93 117 -104 125 -25 17 -67 18 -88 2z m55 -15 c-7 -2 -19 -2 -25
0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m304 1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z"/>
<path d="M7418 2923 c-8 -10 -45 -79 -81 -153 -37 -74 -76 -149 -87 -167 -12
-17 -23 -53 -27 -81 -9 -79 0 -82 244 -82 190 0 204 1 223 20 11 11 20 28 20
38 0 30 -21 96 -37 114 -8 9 -45 79 -82 155 -37 76 -76 146 -85 156 -22 22
-69 22 -88 0z m92 -300 c0 -13 5 -23 10 -23 6 0 10 -7 10 -15 0 -12 -14 -15
-70 -15 -70 0 -83 7 -55 30 8 7 12 16 9 21 -3 5 7 35 21 67 l27 57 24 -50 c13
-27 24 -60 24 -72z m30 -75 c0 -5 -34 -8 -75 -8 -41 0 -75 3 -75 8 0 4 34 7
75 7 41 0 75 -3 75 -7z"/>
<path d="M7832 2928 c-9 -9 -12 -76 -12 -238 0 -162 3 -229 12 -238 7 -7 28
-12 48 -12 20 0 41 5 48 12 9 9 12 75 12 234 0 189 -2 225 -16 238 -18 19 -75
21 -92 4z m55 -14 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M8176 2924 c-13 -12 -16 -43 -17 -157 -1 -168 -6 -191 -39 -195 -38
-6 -50 -21 -50 -63 0 -28 6 -42 22 -53 44 -31 154 -11 177 32 16 30 15 424 -1
440 -17 17 -74 15 -92 -4z"/>
<path d="M8602 2928 c-5 -7 -39 -72 -75 -144 -36 -72 -77 -148 -90 -168 -14
-20 -28 -59 -32 -88 -7 -46 -5 -54 15 -70 20 -16 44 -18 226 -18 194 0 205 1
225 21 17 17 20 28 15 62 -4 23 -12 51 -19 63 -7 11 -49 93 -94 181 -45 88
-89 163 -98 167 -27 10 -62 7 -73 -6z m93 -298 c1 -8 7 -25 13 -37 13 -23 12
-23 -63 -23 -75 0 -88 7 -60 30 8 7 13 16 10 20 -3 5 6 35 21 67 l26 58 25
-50 c15 -27 27 -57 28 -65z m20 -80 c-3 -5 -39 -9 -78 -8 l-72 2 75 6 c41 4
76 7 78 8 2 1 0 -2 -3 -8z"/>
<path d="M3242 2738 c-20 -20 -15 -85 9 -101 15 -11 17 -16 7 -20 -7 -3 34 -5
92 -5 58 0 99 2 92 5 -8 3 -6 10 9 21 23 20 29 73 9 97 -16 19 -199 22 -218 3z
m145 -5 c-20 -2 -52 -2 -70 0 -17 2 0 4 38 4 39 0 53 -2 32 -4z"/>
<path d="M4773 2613 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
